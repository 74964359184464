/**
 * SEO component that queries for data with
 *  Gatsby`s useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type MetaItem = {
	name: string;
	content: string;
};

interface ISiteProps {
	site: {
		siteMetadata: ISEOProps;
	};
}

interface ISEOProps {
	title?: string;
	description?: string;
	url?: string;
	keywords?: string[];
	meta?: MetaItem[];
	image?: string;
	imageWidth?: string;
	imageHeight?: string;
	imageAlt?: string;
	siteUrl?: string;
}

export const Seo: FC<ISEOProps> = ({ title, description }) => {
	const { site } = useStaticQuery<ISiteProps>(
		graphql`
			query {
				site {
					siteMetadata {
						title
						image
						imageAlt
						imageWidth
						imageHeight
						siteUrl: url
						description
						keywords
					}
				}
			}
		`
	);

	const pageTitle = title
		? `${title} | ${site.siteMetadata.title}`
		: site.siteMetadata.title;

	const pageDescription = description ?? site.siteMetadata.description;
	const siteUrl = site.siteMetadata.siteUrl || '';

	return (
		<Helmet
			htmlAttributes={{
				lang: 'nl',
			}}
			title={pageTitle}
			meta={[
				{
					name: 'description',
					content: pageDescription,
				},
				{
					property: 'og:title',
					content: pageTitle,
				},
				{
					property: 'og:description',
					content: pageDescription,
				},
				{
					property: 'og:image',
					content: `${siteUrl}${site.siteMetadata.image}`,
				},
				{
					property: 'og:image:width',
					content: site.siteMetadata.imageWidth,
				},
				{
					property: 'og:image:height',
					content: site.siteMetadata.imageHeight,
				},
				{
					property: 'og:image:alt',
					content: site.siteMetadata.imageAlt,
				},
				{
					property: 'og:url',
					content: siteUrl,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:creator',
					content: '@esrinederland',
				},
				{
					name: 'twitter:title',
					content: pageTitle,
				},
				{
					name: 'twitter:description',
					content: pageDescription,
				},
				{
					name: 'twitter:image',
					content: `${siteUrl}${site.siteMetadata.image}`,
				},
				{
					name: 'twitter:image:alt',
					content: site.siteMetadata.imageAlt,
				},
				{
					name: 'keywords',
					content: site.siteMetadata.keywords?.join(),
				},
			]}
		/>
	);
};
