import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';

interface ILayoutProps {
	isOverflowed?: boolean;
	isBodyLocked?: boolean;
}

const GlobalStyle = createGlobalStyle`
  body {
		position: relative !important;
		margin: 0 !important;
		padding: 0 !important;
		width: 100% !important;
		height: 100% !important;

		${(props: ILayoutProps) =>
			props.isBodyLocked ? 'position: fixed!important' : ''};
		${(props: ILayoutProps) =>
			props.isOverflowed ? 'overflow-x: hidden !important' : ''};
	}

	html {
		overflow-y: auto !important;
	}

	*, h1, h2, h3, h4, p, html {
		font-family: 'Avenir Next LT Pro', Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif !important;
		font-weight: 500;
		padding: 0;
		margin: 0;
	}

	button {
		outline: none;
		box-shadow: none;
		border: none;

		&:active,
		&:focus,
		&:hover {
			outline: none;
			box-shadow: none;
			border: none;
		}
	}
`;

export const Layout: FC<ILayoutProps> = ({
	children,
	isBodyLocked = false,
	isOverflowed = false,
}) => {
	return (
		<>
			<GlobalStyle
				theme={() => ({})}
				isBodyLocked={isBodyLocked}
				isOverflowed={isOverflowed}
			/>
			<Helmet />

			<main>{children}</main>
		</>
	);
};
